<template>
    <div style="margin-top:30px">
      <b-overlay :show="showLoading" no-wrap />
      <validation-observer 
      #default="{ handleSubmit }"
      ref="refFormObserver">
      <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
       <app-collapse
          id="collapse-besoin"
          type="margin"
        >
        <b-row>
          <b-col>
            <h2 class="text-center text-uppercase mb-3">Formulaire de demande de code</h2>
          </b-col>
        </b-row>
        <!-- Votre Cabinet -->
        
        <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard text-uppercase'><u>MES CODES</u></h4>">
            <b-row>
              <b-col md="5">
                <b-form-group
                  label="Vous détenez un code apporteur de l'un de nos partenaires et vous souhaitez synchroniser vos extranets avec BubbleIn ? Merci de préciser votre code"
                >
                  <b-row class="pt-1" v-for="(item, i) in produits" :key="i">
                      <b-col md="12" v-if="item.assureur.nom_com_assureur === 'SWISSLIFE PREVOYANCE ET SANTE'">
                      Nous ne pouvons intégrer vos codes SwissLife en interne, mais vous pourrez bénéficier d'un code spécifique lié à notre convention de partenariat BubbleIn/SwissLife.
                    </b-col>
                    <b-col md="4" class="pt-1 d-flex align-items-center" v-else>
                      <b-form-label :for="`cabinet_des_entreprise_${i}`">{{ capitalizeWords(item.assureur.nom_com_assureur) }}</b-form-label>
                    </b-col>
                    <b-col md="4" class="mt-1" v-if="item.assureur.nom_com_assureur !== 'SWISSLIFE PREVOYANCE ET SANTE'">
                      <validation-provider #default="{ errors }" :name="`code_harmonie_${i}`">
                        <b-form-input
                          v-model="code[getKey(item)]"
                          :id="`code_harmonie_${i}`"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Précisez le code"
                          type="text"
                          class="mb-1"
                          :disabled="isCodeDisabled(item)"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col md="5" class="ml-4">
                <b-form-group
                  label="Vous ne souhaitez pas ouvrir / synchroniser vos codes apporteur avec BubbleIn, merci de cocher les informations ci-dessous"
                >
                  <b-row class="pt-1 mt-1" v-for="(item, i) in produits" :key="i">
                    <b-col md="4" class="pt-1 d-flex align-items-center">
                      <b-form-checkbox v-model="noSyncCodes"  :value="item.assureur.nom_com_assureur" @change="handleCheckboxChange(item)">{{ capitalizeWords(item.assureur.nom_com_assureur) }}</b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>         
            </b-row>
           
        </app-collapse-item>
        <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard text-uppercase'><u>MES INFORMATIONS CABINET</u></h4>">
          <b-row>
              <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Raison sociale" label-for="raison_sociale">
                        <validation-provider #default="{ errors }" name="Raison sociale" rules="required">
                          <b-form-input v-model="cabinet.raison_sociale" :state="errors.length > 0 ? false : null" id="raison_sociale"  placeholder="Raison sociale" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group label="Dénomination commerciale " label-for="denomination_commerciale">
                        <validation-provider #default="{ errors }" name="Dénomination commerciale " rules="required">
                          <b-form-input v-model="cabinet.denomination_commerciale" :state="errors.length > 0 ? false : null" id="denomination_commerciale"  placeholder="Dénomination commerciale" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Effectif" label-for="effectif">
                        <validation-provider #default="{ errors }" name="Effectif" rules="required">
                          <b-form-input v-model="cabinet.effectif" :state="errors.length > 0 ? false : null" id="effectif"  placeholder="Effectif" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Forme juridique" label-for="forme_juridique">
                        <validation-provider #default="{ errors }" name="Forme juridique " rules="required">
                          <b-form-input v-model="cabinet.forme_juridique" :state="errors.length > 0 ? false : null" id="forme_juridique"  placeholder="Forme juridique" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="N° SIRET" label-for="n_siret">
                        <validation-provider #default="{ errors }" name="N° SIRET" rules="required">
                          <b-form-input v-model="cabinet.n_siret" :state="errors.length > 0 ? false : null" id="n_siret"  placeholder="N° SIRET" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="N° ORIAS" label-for="n_orias">
                        <validation-provider #default="{ errors }" name="N° ORIAS" rules="required">
                          <b-form-input v-model="cabinet.n_orias" :state="errors.length > 0 ? false : null" id="n_orias"  placeholder="N° ORIAS" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Capital social" label-for="capital_social">
                        <validation-provider #default="{ errors }" name="Capital social" rules="required">
                          <b-form-input v-model="cabinet.capital_social" :state="errors.length > 0 ? false : null" id="capital_social"  placeholder="Capital social" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Date de création" label-for="date_creation">
                        <validation-provider #default="{ errors }" name="Date de création" rules="required">
                          <flat-pickr
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            v-model="cabinet.date_creation"
                            id="date_creation"
                            placeholder="Date de création"
                            :config="{  dateFormat: 'd/m/Y'}"
                          />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Adresse" label-for="adresse">
                        <validation-provider #default="{ errors }" name="Adresse" rules="required">
                          <b-form-input v-model="cabinet.adresse" :state="errors.length > 0 ? false : null" id="adresse"  placeholder="Adresse" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Code postal" label-for="code_postal">
                        <validation-provider #default="{ errors }" name="Code postal" rules="required">
                          <b-form-input v-model="cabinet.code_postal" :state="errors.length > 0 ? false : null" id="code_postal"  placeholder="Code postal" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Ville" label-for="ville">
                        <validation-provider #default="{ errors }" name="Ville" rules="required">
                          <b-form-input v-model="cabinet.ville" :state="errors.length > 0 ? false : null" id="ville"  placeholder="Ville" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Téléphone portable" label-for="tel">
                        <validation-provider #default="{ errors }" name="Téléphone" rules="required">
                          <b-form-input v-model="cabinet.tel" :state="errors.length > 0 ? false : null" id="tel"  placeholder="Téléphone portable" type="tel" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Téléphone fixe" label-for="telfix">
                        <validation-provider #default="{ errors }" name="Téléphone" >
                          <b-form-input v-model="cabinet.telfix" :state="errors.length > 0 ? false : null" id="telfix"  placeholder="Téléphone fixe" type="tel" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Mail" label-for="email">
                        <validation-provider #default="{ errors }" name="Mail cabinet" rules="required|email">
                          <b-form-input v-model="cabinet.email" :state="errors.length > 0 ? false : null" id="email" placeholder="Mail" type="email" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Civilité du gérant" label-for="civilite_gerant">
                        <validation-provider #default="{ errors }" name="civilite du gérant" rules="required">
                           <b-form-select v-model="cabinet.civilite_gerant" :options="civilite" id="civilite" :state="errors.length > 0 ? false : null" />
                           <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Nom du gérant" label-for="nom_gerant">
                        <validation-provider #default="{ errors }" name="Nom du gérant" rules="required">
                          <b-form-input v-model="upperCaseNomGerant" :state="errors.length > 0 ? false : null" id="nom_gerant" placeholder="Nom du gérant" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Prénom du gérant" label-for="prenom_gerant">
                        <validation-provider #default="{ errors }" name="Prénom du gérant" rules="required">
                          <b-form-input v-model="formattedPrenomGerant" :state="errors.length > 0 ? false : null" id="prenom_gerant" placeholder="Prénom du gérant" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Fonction du gérant" label-for="fonction_gerant">
                        <validation-provider #default="{ errors }" name="Fonction du gérant" rules="required">
                          <b-form-input v-model="cabinet.fonction_gerant" :state="errors.length > 0 ? false : null" id="fonction_gerant"  placeholder="Fonction du gérant (Exemple : DG, PDG, Mandataire sociale...)" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  <b-col md="12">
                    <b-form-group label-cols="3" label="Coordonnées des mandataires sociaux (Nom, prénom, n° de téléphone et email)" label-for="coordonnés_mandataires">
                      <validation-provider #default="{ errors }" name="Expérience du gérant" >
                      <quill-editor style="height:130px;" v-model="cabinet.coordonnés_mandataires" :state="errors.length > 0 ? false : null" id="coordonnés_mandataires" :options="snowOption" />

                        <!-- <b-form-textarea v-model="cabinet.coordonnés_mandataires" :state="errors.length > 0 ? false : null" id="coordonnés_mandataires"  placeholder="Résumé de votre expérience en 10 lignes ou adresser nous votre CV" type="text" /> -->
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                   
                    <b-col md="12">
                      <h6 class="mb-1">Profil</h6>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                      >
                      <validation-provider #default="{ errors }" name="Profil" rules="required">
                        <b-form-checkbox-group
                          v-model="cabinet.selected_profil"
                          :state="errors.length > 0 ? false : null"
                          :options="options_profil_courtiers"
                          name="flavour-2a"
                          stacked
                          class="profil_checkbox"
                        ></b-form-checkbox-group>
                        <span class="text_info">Si oui, merci de nous transmettre la liste de vos co-courtiers.</span>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Au moins un élément doit être sélectionné</b-form-invalid-feedback>
                      </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label-cols="3" label="Compagnie mandante" label-for="compagnie_mandante" style="margin-top:20px;">
                        <validation-provider #default="{ errors }" name="Compagnie mandante" :rules="cabinet.selected_profil.includes('Agent général et courtage à titre accessoire') ? 'required' : null">
                          <b-form-input v-model="cabinet.compagnie_mandante" :state="errors.length > 0 ? false : null" id="compagnie_mandante"  placeholder="Compagnie mandante" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>

                    
                    </b-col>
                    <b-col md="12">
                      <b-row>
                        <b-col md="4" class="pt-1">
                          <b-form-checkbox-group
                          v-model="cabinet.profile_activite">
                          <b-form-checkbox value="activite_compl">Activités complémentaires précisez</b-form-checkbox>
                        </b-form-checkbox-group>
                        </b-col>
                        <b-col md="8">
                          <validation-provider #default="{ errors }" name="Activités complémentaires" :rules="cabinet.profile_activite.includes('activite_compl') ? 'required' : null">
                            <b-form-input class="mb-1" v-model="cabinet.activite_compl" :state="errors.length > 0 ? false : null" id="activite_compl" placeholder="Activités complémentaires" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="12">
                      <h6 class="mb-1">Précisez</h6>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                      >
                        <validation-provider #default="{ errors }" name="Courtier à annexe" :rules="cabinet.selected_profil.includes('Courtier à annexe, précisez') ? 'required' : null">
                          <b-form-checkbox-group
                                v-model="cabinet.selected_profil_mandante"
                                :options="options_profil_mandante"
                                name="flavour-2a"
                                :state="errors.length > 0 ? false : null"
                              ></b-form-checkbox-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Doit etre sélectionné au moins un choix</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
            </b-col>
          </b-row>
        </app-collapse-item>

        <!-- <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard text-uppercase'><u>VOS CONTACTS</u></h4>">
          <b-row v-for="(cnt, index) in contact" :key="index">
              <b-col md="12">
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Nom" label-for="contact_nom">
                        <validation-provider #default="{ errors }" name="Nom" rules="required">
                          <b-form-input v-model="cnt.nom" :state="errors.length > 0 ? false : null" id="contact_nom"  placeholder="Nom" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="Prénom" label-for="contact_prenom">
                        <validation-provider #default="{ errors }" name="Prénom" rules="required">
                          <b-form-input v-model="cnt.prenom" :state="errors.length > 0 ? false : null" id="contact_prenom"  placeholder="Prénom" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="Mail" label-for="contact_email">
                        <validation-provider #default="{ errors }" name="Mail contact" rules="required|email">
                          <b-form-input v-model="cnt.email" :state="errors.length > 0 ? false : null" id="contact_email"  placeholder="Mail" type="email" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3" class="text-right">
                        <b-button
                        size="sm"
                        variant="outline-primary"
                        class="btn-tour-skip mr-1 mt-2"
                        @click="addContact"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="21"
                        />
                      </b-button>
                      <b-button
                        v-if="contact.length > 1"
                        size="sm"
                        variant="primary"
                        class="btn-tour-skip mr-1 mt-2"
                        @click="DeleteContact(index)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          size="22"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
            </b-col>
          </b-row>
        </app-collapse-item> -->

        <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard text-uppercase'><u>VOTRE ACTIVITE</u></h4>">
          <b-row>
              <b-col md="12">
                <h4>Mode de vente</h4>
                <validation-provider name="Le mode de vente" :rules="activite.error_mode_vente ? 'required' : null">
                  <b-form-invalid-feedback :state="activite.error_mode_vente ? false : null">Le mode de vente est obligatoire</b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <b-form-checkbox-group
                          v-model="activite.mode_vente"
                          name="flavour-2a"
                          stacked
                          @change="changeModeVente"
                        >
                        <b-row>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="agence" class="col-12">En agence, nombre de commerciaux</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="En agence nombre de commerciaux" :rules="activite.mode_vente.includes('agence') ? 'required' : null">
                              <b-form-input class="col-6 mb-1" v-model="activite.agence_nbr_commerciaux" :state="errors.length > 0 ? false : null" id="agence_nbr_commerciaux" placeholder="nombre de commerciaux" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="domicile" class="col-12">
                              Domicile ou travail de vos clients, nombre de commerciaux :
                              <!-- <span v-b-popover.hover.top="'Régime de la VAD prévu aux articles L 321-5 et suivants du Code de la consommation'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="'Vente à Distance'" class="cursor-pointer" variant="outline-primary">
                                <feather-icon icon="InfoIcon" size="20" />
                              </span> -->
                            </b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Domicile ou travail nombre de commerciaux" :rules="activite.mode_vente.includes('domicile') ? 'required' : null">
                              <b-form-input class="col-6 mb-1" v-model="activite.domicile_nbr_commerciaux" :state="errors.length > 0 ? false : null" id="domicile_nbr_commerciaux" placeholder="nombre de commerciaux" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="distance_tel" class="col-12">
                              Vente à distance par téléphone, nombre de commerciaux :
                             <span class="text_info" style="font-size:12px">Régime de la VAD prévu aux articles L 321-5 et suivants du Code de la consommation</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            
                            <validation-provider #default="{ errors }" name="Vente à distance nombre de commerciaux" :rules="activite.mode_vente.includes('distance_tel') ? 'required' : null">
                              <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_nbr_commerciaux" :state="errors.length > 0 ? false : null" id="vente_distance_nbr_commerciaux" placeholder="nombre de commerciaux" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <div class="pl-4">
                          <b-row>
                            <b-col md="4" class="pt-1">
                              <b-form-checkbox value="distance_tel_france" class="col-12">Depuis la France</b-form-checkbox>
                            </b-col>
                            <!-- <b-col md="8">
                              <validation-provider #default="{ errors }" name="Vente à distance En France la région" :rules="activite.mode_vente.includes('distance_tel_france') ? 'required' : null">
                                <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_france_region" :state="errors.length > 0 ? false : null" id="vente_distance_france_region" placeholder="" type="text"/>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                              </validation-provider>
                            </b-col> -->
                          </b-row>
                          
                          <b-row>
                            <b-col md="4" class="pt-1">
                              <b-form-checkbox value="distance_tel_en_ue" class="col-12">En UE, précisez le pays</b-form-checkbox>
                            </b-col>
                            <b-col md="8">
                              <validation-provider #default="{ errors }" name="Vente à distance En ue le pays" :rules="activite.mode_vente.includes('distance_tel_en_ue') ? 'required' : null">
                                  <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_pays_en_ue" :state="errors.length > 0 ? false : null" id="vente_distance_ue_pays" placeholder="le pays" type="text"/>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                          </b-row>

                          <div class="pl-1">
                            <b-form-checkbox value="distance_tel_Succursale_en_ue" class="col-12">Succursale ou filiale</b-form-checkbox>
                            <span class="text_info">Si oui, merci de nous transmettre les documents de la succursale.</span>
                              <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_tel_prestataire_en_ue" class="col-12">Prestataire, précisez</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance En ue prestataire" :rules="activite.mode_vente.includes('distance_tel_prestataire_en_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_prestataire_en_eu" :state="errors.length > 0 ? false : null" id="vente_distance_prestataire" placeholder="Prestataire" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_tel_autre_en_ue" class="col-12">Autre, précisez</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance En ue autre" :rules="activite.mode_vente.includes('distance_tel_autre_en_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_autre_en_eu" :state="errors.length > 0 ? false : null" id="vente_distance_ue_autre" placeholder="Autre" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                          </div>
                          <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_tel_hors_ue" class="col-12">Hors UE, précisez le pays</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance hors ue le pays" :rules="activite.mode_vente.includes('distance_tel_hors_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_pays_hors_eu" :state="errors.length > 0 ? false : null" id="vente_distance_ue_pays" placeholder="le pays" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                          </b-row>
                          
                          
                          <div class="pl-1">
                            <b-form-checkbox value="distance_tel_Succursale_hors_ue" class="col-12">Succursale ou filiale</b-form-checkbox>
                            <span class="text_info">Si oui, merci de nous transmettre les documents de la succursale.</span>
                            <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_tel_prestataire_hors_ue" class="col-12">Prestataire, précisez</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance hors ue prestataire" :rules="activite.mode_vente.includes('distance_tel_prestataire_hors_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_prestataire_hors_eu" :state="errors.length > 0 ? false : null" id="vente_distance_prestataire" placeholder="Prestataire" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>                                  
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_tel_autre_hors_ue" class="col-12">Autre, précisez</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance hors ue autre" :rules="activite.mode_vente.includes('distance_tel_autre_hors_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_autre_hors_eu" :state="errors.length > 0 ? false : null" id="vente_distance_ue_autre" placeholder="Autre" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                          </div>

                        </div>
                        <b-row>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="distance_internet" class="col-12">
                              Vente à distance conclue par internet, nombre de commerciaux :        
                              <span class="text_info" style="font-size:12px">Régime de la VAD prévu aux articles L 321-5 et suivants du Code de la consommation</span>
                            </b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Vente à distance internet nombre de commerciaux" :rules="activite.mode_vente.includes('distance_internet') ? 'required' : null">
                              <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_internet_nbr_commerciaux" :state="errors.length > 0 ? false : null" id="vente_distance_internet_nbr_commerciaux" placeholder="nombre de commerciaux" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        
                        
                        <div class="pl-4">
                              <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_internet_france" class="col-12">Depuis la France</b-form-checkbox>
                                </b-col>
                                <!-- <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance internet en france la région" :rules="activite.mode_vente.includes('distance_internet_france') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_internet_france_region" :state="errors.length > 0 ? false : null" id="vente_distance_internet_france_region" placeholder="" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col> -->
                              </b-row>
                              <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_internet_en_ue" class="col-12">En UE, précisez le pays</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance internet en ue le pays" :rules="activite.mode_vente.includes('distance_internet_en_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_internet_pays_en_ue" :state="errors.length > 0 ? false : null" id="vente_distance_internet_ue_pays" placeholder="le pays" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                          
                          

                          
                          
                          <div class="pl-1">
                            <b-form-checkbox value="distance_internet_Succursale_en_ue" class="col-12">Succursale ou filiale</b-form-checkbox>
                            <span class="text_info">Si oui, merci de nous transmettre les documents de la succursale.</span>
                            <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_internet_prestataire_en_ue" class="col-12">Prestataire, précisez</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance internet en ue prestataire" :rules="activite.mode_vente.includes('distance_internet_prestataire_en_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_internet_prestataire_en_ue" :state="errors.length > 0 ? false : null" id="vente_distance_internet_prestataire" placeholder="Prestataire" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                              </b-row>

                              <b-row>
                                <b-col md="4" class="pt-1">
                                  <b-form-checkbox value="distance_internet_autre_en_ue" class="col-12">Autre, précisez</b-form-checkbox>
                                </b-col>
                                <b-col md="8">
                                  <validation-provider #default="{ errors }" name="Vente à distance internet en ue autre" :rules="activite.mode_vente.includes('distance_internet_autre_en_ue') ? 'required' : null">
                                    <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_internet_autre_en_ue" :state="errors.length > 0 ? false : null" id="vente_distance_internet_ue_autre" placeholder="Autre" type="text"/>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                  </validation-provider>
                                </b-col>
                              </b-row>                            
                          </div>
                          <b-row>
                            <b-col md="4" class="pt-1">
                              <b-form-checkbox value="distance_internet_hors_ue" class="col-12">Hors UE, précisez le pays</b-form-checkbox>
                            </b-col>
                            <b-col md="8">
                              <validation-provider #default="{ errors }" name="Vente à distance internet hors ue le pays" :rules="activite.mode_vente.includes('distance_internet_hors_ue') ? 'required' : null">
                                <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_pays_hors_ue" :state="errors.length > 0 ? false : null" id="vente_distance_internet_ue_pays" placeholder="le pays" type="text"/>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <div class="pl-1">
                            <b-form-checkbox value="distance_internet_Succursale_hors_ue" class="col-12">Succursale ou filiale</b-form-checkbox>
                            <span class="text_info">Si oui, merci de nous transmettre les documents de la succursale.</span>
                            <b-row>
                              <b-col md="4" class="pt-1">
                                <b-form-checkbox value="distance_internet_prestataire_hors_ue" class="col-12">Prestataire, précisez</b-form-checkbox>
                              </b-col>
                              <b-col md="8">
                                <validation-provider #default="{ errors }" name="Vente à distance internet hors ue prestataire" :rules="activite.mode_vente.includes('distance_internet_prestataire_hors_ue') ? 'required' : null">
                                  <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_internet_prestataire_hors_ue" :state="errors.length > 0 ? false : null" id="vente_distance_internet_prestataire" placeholder="Prestataire" type="text"/>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col md="4" class="pt-1">
                                <b-form-checkbox value="distance_internet_autre_hors_ue" class="col-12">Autre, précisez</b-form-checkbox>
                              </b-col>
                              <b-col md="8">
                                <validation-provider #default="{ errors }" name="Vente à distance internet hors ue autre" :rules="activite.mode_vente.includes('distance_internet_autre_hors_ue') ? 'required' : null">
                                  <b-form-input class="col-6 mb-1" v-model="activite.vente_distance_internet_autre_hors_ue" :state="errors.length > 0 ? false : null" id="vente_distance_internet_ue_autre" placeholder="Autre" type="text"/>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                            </b-row>
                          </div>

                        </div>
                      </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>

                  </b-row>
              </b-col>
              <b-col md="12" v-if="activite.mode_vente.includes('distance_tel') || activite.mode_vente.includes('distance_internet') ">
                <h4>Compléments d’informations en cas de vente à distance</h4>
                     <b-form-group>
                        <b-form-checkbox-group
                          v-model="activite.complement_info_cas_vente_distence"
                          name="flavour-2a"
                          stacked
                        >
                  <b-row>
                      <b-col md="4" class="pt-1">
                        <b-form-checkbox value="achat_lead" class="col-12">Achat de Leads, précisez</b-form-checkbox>
                      </b-col>
                      <b-col md="8" class="pt-1 d-flex ">
                          <b-col md="3" class="pt-1">
                            <b-form-checkbox value="achat_lead_chaud" class="col-12">Leads Chauds/appels sollicités</b-form-checkbox>
                          </b-col>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="achat_lead_froid" class="col-12">Leads froids / appels non sollicités</b-form-checkbox>
                          </b-col>    
                      </b-col> 
                  </b-row>
                  <b-row>
                      <b-col md="4" class="pt-1">
                      </b-col>
                      <b-col md="8" class="pt-1 d-flex ">
                          <b-col md="8">
                            <span>Fournisseurs de Leads</span>
                              <validation-provider #default="{ errors }" name="Fournisseur des leads">
                                  <b-form-input class="col-6 mb-1" v-model="activite.fournisseur_lead" :state="errors.length > 0 ? false : null" id="fournisseur_lead" placeholder="Vos fournisseurs de leads" type="text"/>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                          </b-col>
                          <b-col md="4">
                          </b-col>
                      </b-col> 
                  </b-row>
                 <b-row>
                    <b-col md="6" class="pt-1">
                      <b-form-checkbox value="control_bloctel" class="col-12 ">Vous effectuez, vous même, le contrôle BLOCTEL de vos fichiers de prospection</b-form-checkbox>
                      <b-form-checkbox value="fourni_control_bloctel" class="col-12 mt-1">Votre fournisseurs de Lead effectue le contrôle BLOCTEL de vos fichiers de prospection</b-form-checkbox>
                      <b-form-checkbox value="service_acc_tele" class="col-12 mt-1">Vous disposez d'un service d’accueil téléphonique</b-form-checkbox>
                      
                      <!-- Checkbox et input Missions sur la même ligne -->
                      <b-row>
                        <b-col md="8" class="d-flex align-items-center mt-1">
                          <b-form-checkbox value="service_qualite_interne">Vous disposez d'un service qualité interne, précisez ses missions</b-form-checkbox>
                        </b-col>
                        <b-col md="4" >
                          <validation-provider #default="{ errors }" name="Missions" :rules="activite.complement_info_cas_vente_distence.includes('service_qualite_interne') ? 'required' : null">
                            <b-form-input v-model="activite.missions" :state="errors.length > 0 ? false : null" id="missions" placeholder="Missions" type="text"/>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  </b-form-checkbox-group>
                  </b-form-group>
              </b-col>
            <!-- Etablissement Section -->
           <b-col md="12" v-if="activite.mode_vente.includes('distance_tel') || activite.mode_vente.includes('distance_internet') ">
                  <b-row>
                    <b-col md="12">
                      <h5 class="mt-1" style="text-decoration:underline">Etablissement réalisant les ventes (call-center)</h5>
                      <b-form-group label="" >
                       
                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Relation courtier - établissement (propriétaire/sous-traitant)
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Relation courtier - établissement" >
                              <b-form-input class="col-6 mb-1" v-model="activite.relation_courtier_etablissement" id="relation_courtier_etablissement" :state="errors.length > 0 ? false : null" placeholder="Relation courtier - établissement (propriétaire/sous-traitant)" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Nom de la structure juridique
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Nom de la structure juridique" >
                              <b-form-input class="col-6 mb-1" v-model="activite.nom_structure_juridique" id="nom_structure_juridique" :state="errors.length > 0 ? false : null" placeholder="Nom de la structure juridique" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Nom commercial (si différent)
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name=" Nom commercial " >
                              <b-form-input class="col-6 mb-1" v-model="activite.nom_commercial" id="nom_commercial" :state="errors.length > 0 ? false : null" placeholder="Nom commercial (si différent)" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Adresse complète
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Adresse complète">
                              <b-form-input class="col-6 mb-1" v-model="activite.adress_complete" id="entreprise_" :state="errors.length > 0 ? false : null" placeholder="Adresse complète" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                 <b-row>
                    <b-col md="6" class="pt-1 d-flex" style="margin-left:-10px">
                      <b-col md="6">
                        - Nombre de salariés vendeurs
                      </b-col>
                      <b-col md="6">
                        <validation-provider v-slot="{ errors }" name="Nombre de salariés vendeurs">
                          <b-form-input class="mb-1" v-model="activite.nombre_salaries" id="nombre_salaries" :state="errors.length > 0 ? false : null" placeholder="Nombre de salariés vendeurs" type="text"/>
                          <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </b-col>
                    </b-col>
                    <b-col md="6" class="pt-1 d-flex">
                      <b-col md="6">
                        - Nombre de managers  
                      </b-col>
                      <b-col md="6">
                        <validation-provider v-slot="{ errors }" name="Nombre de managers">
                          <b-form-input class="mb-1" v-model="activite.nombre_managers" id="nombre_managers" :state="errors.length > 0 ? false : null" placeholder="Nombre de managers" type="text"/>
                          <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </b-col>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="6" class="pt-1 d-flex" style="margin-left:-10px">
                      <b-col md="6">
                        - Part dans votre CA en % 
                      </b-col>
                      <b-col md="6">
                        <validation-provider v-slot="{ errors }" name="Part dans votre CA en %">
                          <b-form-input class="mb-1" v-model="activite.part_ca" id="part_ca" :state="errors.length > 0 ? false : null" placeholder="Part dans votre CA en %" type="text"/>
                          <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </b-col>
                    </b-col>
                    <b-col md="6" class="pt-1 d-flex">
                      <b-col md="6">
                        - Nombre de contrats vendus en VAD par mois  
                      </b-col>
                      <b-col md="6">
                        <validation-provider v-slot="{ errors }" name="Nombre de contrats vendus en VAD par mois">
                          <b-form-input class="mb-1" v-model="activite.nombre_contrats_vendus" id="nombre_contrats_vendus" :state="errors.length > 0 ? false : null" placeholder="Nombre de contrats vendus en VAD par mois" type="text"/>
                          <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </b-col>
                    </b-col>
                  </b-row>   
                  </b-form-group>
                </b-col>
              </b-row>
           </b-col>
            <!-- Responsable Section -->
           <b-col md="12" v-if="activite.mode_vente.includes('distance_tel') || activite.mode_vente.includes('distance_internet') ">
                  <b-row>
                    <b-col md="12">
                      <h5 class="mt-1" style="text-decoration:underline">Responsable du call-center</h5>
                      <b-form-group label="" >                 
                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Nom 
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Nom" >
                              <b-form-input class="col-6 mb-1" v-model="activite.responsable_nom" id="responsable_nom" :state="errors.length > 0 ? false : null" placeholder="Nom" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Prénom
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Prénom" >
                              <b-form-input class="col-6 mb-1" v-model="activite.responsable_prenom" id="responsable_prenom" :state="errors.length > 0 ? false : null" placeholder="Prénom" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Mail
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Mail" >
                              <b-form-input class="col-6 mb-1" v-model="activite.responsable_mail" id="responsable_mail" :state="errors.length > 0 ? false : null" placeholder="Mail" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="4" class="pt-1">
                            - Téléphone
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Téléphone">
                              <b-form-input class="col-6 mb-1" v-model="activite.responsable_tele" id="responsable_tele" :state="errors.length > 0 ? false : null" placeholder="Téléphone" type="Number"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-form-group>
                </b-col>
                     <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group style="margin-bottom:0px;">
                        <label class="float-left pr-4 mb-1">Encaissez-vous des fonds ?</label>
                        <validation-provider #default="{ errors }" name="Encaissez-vous des fonds ?" rules="required">
                          <b-form-radio-group v-model="activite.encaisse_fonds" :options="optionsEncaisseFonds" name="radio-encaisse-fonds" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                      <span class="text_info" style="margin-bottom:15px;">Si oui, merci de nous transmettre l’attestation de garantie financière.</span>
                    </b-col>

                  </b-row>
            </b-col>
              </b-row>
           </b-col>
              <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group style="margin-bottom:0px;">
                        <label class="float-left pr-4 mb-1">En création ?</label>
                        <validation-provider #default="{ errors }" name="En création" rules="required">
                          <b-form-radio-group v-model="activite.en_creation" :options="en_creation" name="radio-en-creation" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
            </b-col>
              <b-col md="12" v-if='activite.en_creation == "non"'>
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label class="float-left pr-4 mb-1">Chiffre d’affaires annuel N -1</label>
                        <validation-provider #default="{ errors }" name="Chiffre d’affaires annuel N -1" rules="required">
                          <b-form-radio-group v-model="activite.chiffre_affaire" :options="optionsChiffreAffaire" name="radio-chiffre-affaire" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
            </b-col>
            <b-col md="12" v-if='activite.en_creation == "oui"'>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <label class="float-left pr-4 mb-1">Chiffre d’affaires annuel prévisionnel</label>
                          <validation-provider #default="{ errors }" name="Chiffre d’affaires annuel N -1" rules="required">
                            <b-form-radio-group v-model="activite.chiffre_affaire" :options="optionsChiffreAffaire" name="radio-chiffre-affaire" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    </b-row>
              </b-col>
            <!-- <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <label class="float-left pr-4 mb-1">Répartition de votre chiffre d’affaires </label>
                      </b-form-group>
                    </b-col>

                  </b-row>
            </b-col>
            -->
            <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group>
                        <h4 class="pr-4 mb-1">Répartition de votre chiffre d’affaires </h4>
                        <validation-provider name="Répartition" :rules="activite.error_repartition ? 'required' : null">
                          <b-form-invalid-feedback :state="activite.error_repartition ? false : null">La répartition de votre chiffre d'affaire est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
            </b-col>
            <b-col md="12">
                  <b-row>
                    <b-col md="6">
                        <label class="text_info mt-3">Répartition Globale  <span style="font-style: italic;font-weight: 500;">(La ligne doit faire 100%)</span></label> 
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <b-row>
                          <b-col md="4">
                            <label class="pr-4 mb-1">PARTICULIERS</label>
                            <b-form-input v-model="activite.iard_particulie" id="iard_particulie" :state="activite.error_iard == true ? false : null" @blur="blur_iard" placeholder="___%" type="number" />
                          </b-col>
                          <b-col md="4">
                            <label class="pr-4 mb-1">PROFESSIONNELS</label>
                            <b-form-input v-model="activite.iard_professionnel" id="iard_professionnel" :state="activite.error_iard == true ? false : null" @blur="blur_iard" placeholder="___%" type="number" />
                          </b-col>
                          <b-col md="4">
                            <label class="pr-4 mb-1">ENTREPRISES</label>
                            <b-form-input v-model="activite.iard_entreprise" id="iard_entreprise" :state="activite.error_iard == true ? false : null" @blur="blur_iard" placeholder="___%" type="number" />
                          </b-col>
                          <b-form-invalid-feedback :state="activite.error_iard == true ? false : null">Le total doit être égal à 100%</b-form-invalid-feedback>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
            </b-col>
            <b-col md="12">
                  <b-row>
                    <b-col md="6">
                        <label class="text_info mt-3 ">Répartition par  Risque <span style="font-style: italic;font-weight: 500;">(La ligne doit faire 100%)</span></label> 
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <b-row>
                          <b-col md="4">
                            <label class="pr-4 mb-1">IARD</label>
                            <b-form-input v-model="activite.repart_iard" id="repart_iard" :state="activite.error_repart_by_risque == true ? false : null" @blur="blur_repatition_by_risque" placeholder="___%" type="number" />
                          </b-col>
                          <b-col md="4">
                            <label class="pr-4 mb-1">PREVOYANCE ET SANTE</label>
                            <b-form-input v-model="activite.repart_prevoyance" id="repart_prevoyance" :state="activite.error_repart_by_risque == true ? false : null" @blur="blur_repatition_by_risque" placeholder="___%" type="number" />
                          </b-col>
                          <b-col md="4">
                            <label class="pr-4 mb-1">EPARGNE / RETRAITE</label>
                            <b-form-input v-model="activite.repart_epargne" id="repart_epargne" :state="activite.error_repart_by_risque == true ? false : null" @blur="blur_repatition_by_risque" placeholder="___%" type="number" />
                          </b-col>
                          <b-form-invalid-feedback :state="activite.error_repart_by_risque == true ? false : null">Le total doit être égal à 100%</b-form-invalid-feedback>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
            </b-col>
            <b-col md="12">
                  <b-row>
                    <b-col md="5">
                      <label class="mt-2"></label>
                      <b-form-group>
                        <label>Précisez pour chaque typologie de risque</label>
                        <label class="d-block mt-1">IARD</label>
                        <validation-provider #default="{ errors }" name="IARD" >
                        <b-form-checkbox-group
                          v-model="activite.repartition_iard"
                          :state="errors.length > 0 ? false : null"
                          name="flavour-2a"
                          stacked
                          
                        >
                        <b-form-checkbox value="Auto">Auto</b-form-checkbox>
                        <b-form-checkbox value="2 roues">2 roues</b-form-checkbox>
                        <b-form-checkbox value="MRH / PNO">MRH / PNO</b-form-checkbox>
                        <b-form-checkbox value="MRI">MRI</b-form-checkbox>
                        <b-form-checkbox value="MRP">MRP</b-form-checkbox>
                        <b-form-checkbox value="RC générale">RC générale</b-form-checkbox>
                        <b-form-checkbox value="RC professionnelle">RC professionnelle</b-form-checkbox>
                        <b-form-checkbox value="RC décennale">RC décennale</b-form-checkbox>
                        <b-form-checkbox value="PJ">PJ</b-form-checkbox>
                        <b-form-checkbox value="Santé animale">Santé animale</b-form-checkbox>
                        <b-row>
                          <b-col md="4" class="pt-2">
                            <b-form-checkbox value="Autres">Autres, précisez</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Autres" :rules="activite.repartition_iard.includes('Autres') ? 'required' : null">
                              <b-form-input v-model="activite.autre_repartition_iard" id="autre_repartition_iard" :state="errors.length > 0 ? false : null" class="text-capitalize mt-1 col-6" placeholder="Autres" type="text" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        
                        
                        
                      </b-form-checkbox-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Au moins un élément doit être sélectionné</b-form-invalid-feedback>
                    </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="7" class="text-center">
                 
                    </b-col> -->
                  </b-row>
            </b-col>
            <b-col md="12">
                  <b-row>
                    <b-col md="5">
                      <b-form-group>
                        <label>PREVOYANCE ET SANTE</label>
                        <validation-provider #default="{ errors }" name="PREVOYANCE ET SANTE" >
                        <b-form-checkbox-group
                          v-model="activite.repartition_prevoyance"
                          :state="errors.length > 0 ? false : null"
                          name="flavour-prevoyance"
                          stacked
                        >
                        <b-form-checkbox value="Prévoyance">Prévoyance</b-form-checkbox>
                        <b-form-checkbox value="Santé">Santé</b-form-checkbox>
                        <b-form-checkbox value="Obsèques">Obsèques</b-form-checkbox>
                        <b-form-checkbox value="Emprunteur">Emprunteur</b-form-checkbox>
                        <b-row>
                          <b-col md="4" class="pt-2">
                            <b-form-checkbox value="Autres">Autres, précisez</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Autres" :rules="activite.repartition_prevoyance.includes('Autres') ? 'required' : null">
                              <b-form-input v-model="activite.autre_repartition_prevoyance" id="autre_repartition_prevoyance" :state="errors.length > 0 ? false : null" class="text-capitalize mt-1 col-6" placeholder="Autres" type="text" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-form-checkbox-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Au moins un élément doit être sélectionné</b-form-invalid-feedback>
                    </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="7" class="text-center">
                      <b-row>
                        <b-col md="4">
                          <b-form-input v-model="activite.prevoyance_particulie" id="prevoyance_particulie" :state="activite.error_prevoyance == true ? false : null" @blur="blur_prevoyance" placeholder="___%" type="number" />
                        </b-col>
                        <b-col md="4">
                          <b-form-input v-model="activite.prevoyance_professionnel" id="prevoyance_professionnel" :state="activite.error_prevoyance == true ? false : null" @blur="blur_prevoyance" placeholder="___%" type="number" />
                        </b-col>
                        <b-col md="4">
                          <b-form-input v-model="activite.prevoyance_entreprise" id="prevoyance_entreprise" :state="activite.error_prevoyance == true ? false : null" @blur="blur_prevoyance" placeholder="___%" type="number" />
                        </b-col>
                        <b-form-invalid-feedback :state="activite.error_prevoyance == true ? false : null">Le total doit être égal à 100%</b-form-invalid-feedback>
                      </b-row>
                    </b-col> -->
                  </b-row>
            </b-col>
            <b-col md="12">
                  <b-row>
                    <b-col md="5">
                      <b-form-group>
                        <label>EPARGNE / RETRAITE </label>
                        <validation-provider #default="{ errors }" name="EPARGNE / RETRAITE" >
                        <b-form-checkbox-group
                          v-model="activite.repartition_epargne"
                          :state="errors.length > 0 ? false : null"
                          name="flavour-epargne"
                          stacked
                        >
                        <b-form-checkbox value="Assurance vie">Assurance vie</b-form-checkbox>
                        <b-form-checkbox value="Epargne retraite">Epargne retraite</b-form-checkbox>
                        <b-row>
                          <b-col md="4" class="pt-2">
                            <b-form-checkbox value="Autres">Autres, précisez</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Autres" :rules="activite.repartition_epargne.includes('Autres') ? 'required' : null">
                              <b-form-input v-model="activite.autre_repartition_epargne" id="autre_repartition_epargne" :state="errors.length > 0 ? false : null" placeholder="Autres" class="text-capitalize mt-1 col-6" type="text" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-form-checkbox-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Au moins un élément doit être sélectionné</b-form-invalid-feedback>
                    </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="7" class="text-center">
                      <b-row>
                        <b-col md="4">
                          <b-form-input v-model="activite.epargne_particulie" id="epargne_particulie" :state="activite.error_epargne == true ? false : null" @blur="blur_epragne" placeholder="___%" type="number" />
                        </b-col>
                        <b-col md="4">
                          <b-form-input v-model="activite.epargne_professionnel" id="epargne_professionnel" :state="activite.error_epargne == true ? false : null" @blur="blur_epragne" placeholder="___%" type="number" />
                        </b-col>
                        <b-col md="4">
                          <b-form-input v-model="activite.epargne_entreprise" id="epargne_entreprise" :state="activite.error_epargne == true ? false : null" @blur="blur_epragne" placeholder="___%" type="number" />
                        </b-col>
                        <b-form-invalid-feedback :state="activite.error_epargne == true ? false : null">Le total doit être égal à 100%</b-form-invalid-feedback>
                      </b-row>
                    </b-col> -->
                  </b-row>
            </b-col>
          </b-row>
        </app-collapse-item>

        <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard text-uppercase'><u>VOTRE DEVOIR D’INFORMATION</u></h4>">
          <b-row>
              <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Rémunération">
                        <validation-provider #default="{ errors }" name="Rémunération" rules="required">
                        <b-form-checkbox-group
                          v-model="devoir.remuneration"
                          :state="errors.length > 0 ? false : null"
                          name="flavour-2a"
                          stacked
                        >
                        <b-form-checkbox value="Commission">Commission</b-form-checkbox>
                        <b-form-checkbox value="Honoraires">Honoraires</b-form-checkbox>
                        <b-form-checkbox value="Frais de courtage">Frais de courtage</b-form-checkbox>
                        <b-row>
                          <b-col md="4" class="pt-2">
                            <b-form-checkbox value="Autres">Autres, précisez</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="Autres" :rules="devoir.remuneration.includes('Autres') ? 'required' : null">
                              <b-form-input v-model="devoir.autre_remuneration" id="autre_remuneration" :state="errors.length > 0 ? false : null" class="text-capitalize mt-1 col-6" placeholder="Autres" type="text" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-form-checkbox-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Au moins un élément doit être sélectionné</b-form-invalid-feedback>
                    </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
            </b-col>

            <b-col md="12">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Liens avec des entreprises d’assurance">
                        <b-form-checkbox-group
                          v-model="devoir.lienEntreprises"
                          name="flavour-2a"
                          stacked
                        >
                        <b-row>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="Vous avez un lien exclusivité" class="col-12">Vous avez un lien d’exclusivité avec une entreprise d’assurance, laquelle</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="une entreprise d’assurance" :rules="devoir.lienEntreprises.includes('Vous avez un lien exclusivité') ? 'required' : null">
                              <b-form-input class="col-6 mb-1" v-model="devoir.lien_exc_entreprise" id="lien_exc_entreprise" :state="errors.length > 0 ? false : null" placeholder="une entreprise d’assurance" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="Votre cabinet de courtage détient plus 10" class="col-12">Votre cabinet de courtage détient plus de 10% des droits de vote et/ou de capital d’une ou plusieurs entreprises d'assurance, lesquelles</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="une entreprise d’assurance" :rules="devoir.lienEntreprises.includes('Votre cabinet de courtage détient plus 10') ? 'required' : null">
                              <b-form-input class="col-6 mb-1" v-model="devoir.cabinet_un_entreprise" id="cabinet_un_entreprise" :state="errors.length > 0 ? false : null" placeholder="des entreprises d'assurance" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col md="4" class="pt-1">
                            <b-form-checkbox value="Une ou plusieurs entreprises" class="col-12">Une ou plusieurs entreprises d'assurance détiennent plus de 10% des droits de votre et/ou de capital de votre cabinet de courtage, lesquelles</b-form-checkbox>
                          </b-col>
                          <b-col md="8">
                            <validation-provider #default="{ errors }" name="une entreprise d’assurance" :rules="devoir.lienEntreprises.includes('Une ou plusieurs entreprises') ? 'required' : null">
                              <b-form-input class="col-6 mb-1" v-model="devoir.cabinet_des_entreprise" id="cabinet_des_entreprise" :state="errors.length > 0 ? false : null" placeholder="des entreprises d'assurance" type="text"/>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>

                  </b-row>
            </b-col>
            <b-col md="12">
              <h4>Votre service réclamations</h4>
            </b-col>
            <b-col md="12">
                      <b-form-group label="Adresse" label-for="devoir_adresse">
                        <validation-provider #default="{ errors }" name="Adresse service de réclamations" rules="required">
                          <b-form-input v-model="devoir.adresse" :state="errors.length > 0 ? false : null" id="devoir_adresse"  placeholder="Adresse" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
            </b-col>
            <b-col md="6">
                      <b-form-group label="Code postal" label-for="devoir_code_postal">
                        <validation-provider #default="{ errors }" name="Code postal réclamations" rules="required">
                          <b-form-input v-model="devoir.code_postal" :state="errors.length > 0 ? false : null" id="devoir_code_postal"  placeholder="Code postal" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Ville" label-for="devoir_ville">
                        <validation-provider #default="{ errors }" name="Ville réclamations" rules="required">
                          <b-form-input v-model="devoir.ville" :state="errors.length > 0 ? false : null" id="devoir_ville"  placeholder="Ville" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Téléphone" label-for="devoir_tel">
                        <validation-provider #default="{ errors }" name="Téléphone réclamations" rules="required">
                          <b-form-input v-model="devoir.tel" :state="errors.length > 0 ? false : null" id="devoir_tel"  placeholder="Téléphone" type="tel" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Mail" label-for="devoir_email">
                        <validation-provider #default="{ errors }" name="Mail réclamations" rules="required">
                          <b-form-input v-model="devoir.email" :state="errors.length > 0 ? false : null" id="devoir_email"  placeholder="Mail" type="email" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Site internet" label-for="devoir_site_internet">
                        
                          <b-form-input v-model="devoir.site" id="devoir_site_internet"  placeholder="Site internet" type="text" />
                          
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                    <h4>Le service de médiation auquel vous avez adhéré</h4>
                    </b-col>
                    <b-col md="12">
                              <b-form-group label="Adresse" label-for="devoir_adresse_mediation">
                                <validation-provider #default="{ errors }" name="Adresse service de médiation" rules="required">
                                  <b-form-input v-model="devoir.adresse_mediation" :state="errors.length > 0 ? false : null" id="devoir_adresse_mediation"  placeholder="Adresse" type="text" />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                              <p class="text_info" style="font-size:14px"><feather-icon class="box-icon-text" icon="AlertTriangleIcon" size="22" /> L'adresse ne doit pas être celle de votre cabinet, mais par exemple La médiation de l'assurance ou AME Médiation.</p>
                    </b-col>
                    <b-col md="6">
                              <b-form-group label="Code postal" label-for="devoir_code_postal_mediation">
                                <validation-provider #default="{ errors }" name="Code postal service de médiation" rules="required">
                                  <b-form-input v-model="devoir.code_postal_mediation" :state="errors.length > 0 ? false : null" id="devoir_code_postal_mediation"  placeholder="Code postal" type="text" />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Ville" label-for="devoir_ville_mediation">
                                <validation-provider #default="{ errors }" name="Ville service de médiation" rules="required">
                                  <b-form-input v-model="devoir.ville_mediation" :state="errors.length > 0 ? false : null" id="devoir_ville_mediation"  placeholder="Ville" type="text" />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <b-col md="6">
                              <b-form-group label="Téléphone" label-for="devoir_tel_mediation">
                                <validation-provider #default="{ errors }" name="Téléphone service de médiation" rules="required">
                                  <b-form-input v-model="devoir.tel_mediation" :state="errors.length > 0 ? false : null" id="devoir_tel_mediation"  placeholder="Téléphone" type="tel" />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Mail" label-for="devoir_email_mediation">
                                  <b-form-input v-model="devoir.email_mediation" id="devoir_email_mediation"  placeholder="Mail" type="email" />
                              </b-form-group>
                            </b-col>
                            <b-col md="12">
                              <b-form-group label="Site internet" label-for="devoir_site_internet_mediation">
                                
                                  <b-form-input v-model="devoir.site_mediation" id="devoir_site_internet_mediation"  placeholder="Site internet" type="text" />
                                  
                              </b-form-group>
                            </b-col>
          </b-row>
        </app-collapse-item>
        <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard text-uppercase'><u>SIGNATURE</u></h4>">
          <b-row>
            <b-col md="12">
                      <b-form-group class="signature-checkbox">
                        <b-form-checkbox-group
                          v-model="signature.checkbox"
                          name="flavour-2a"
                          stacked
                        >
                        <b-form-checkbox value="emission"  disabled>Je demande l’émission d’une convention de distribution avec les partenaires présents sur la MarketPlace BubbleIn</b-form-checkbox>
                        <b-form-checkbox value="ouverture" disabled>Je demande l’ouverture d’un compte extranet, lorsqu’il est proposé par l’assureur</b-form-checkbox>
                      </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
            <b-col md="12">
              <h6>En complément, merci de déposer dans votre coffre fort conformité ou d’envoyer au <a href="mailto:support@bubble-in.com">support@bubble-in.com</a>, la liste des documents ci-dessous :</h6>
              <ul>
                <li>Un extrait KBIS de moins de 3 mois;</li>
                <li>Une attestation d’inscription à l’ORIAS;</li>
                <li>Une attestation d’adhésion à une association professionnelle d’autorégulation;</li>
                <li>Une attestation de responsabilité civile professionnelle;</li>
                <li>Une copie de la pièce d’identité du gérant;</li>
                <li>Les documents de succursale le cas échéant;</li>
                <li>Une attestation de garantie financière le cas échéant;</li>
                <li>Un relevé d’identité bancaire;</li>
                <li>Un CV;</li>
                <li>Un document justifiant un abonnement au service Bloctel pour les VADistes;</li>
                <li>Les statuts;</li>
                <li>Carte nationale d'identité (CNI) du gérant et des actionnaires majoritaires ou des associés majoritaires;</li>
                <li>CNI des bénéficiaires effectifs (s'ils sont différents);</li>
                <li>La carte d’identité des mandataires sociaux;</li>
                <li>Une attestation sur l’honneur des formations DDA;</li>
                <li>Un justificatif de capacité professionnel;</li>


              </ul>
              <h6><img src="@/assets/favicon.svg" alt="Logo" style="height:20px;width:20px;margin-right:5px;"/>Pour information, l'activation des codes est soumise à la participation d'un atelier Marketplace</h6>
            </b-col>
          </b-row>
        </app-collapse-item>    
        </app-collapse>
        <b-row class="mt-2 mb-4">
          <b-col lg="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="float-right"
              size="lg"
              type="submit"
              :disabled="isDisabled"
            >
              Signer
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>
    </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from "vue-select"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TheFormSpinbuttonWithInput from '@core/components/input-number/TheFormSpinbuttonWithInput.vue'
import { BFormSpinbutton } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters } from "vuex";
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import { quillEditor } from 'vue-quill-editor'

import 'cleave.js/dist/addons/cleave-phone.us'
import { rules } from '@core/libs/validations'
import { required,email } from '@validations'
import {
  BOverlay,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormValidFeedback,
  BAlert,
  VBTooltip,
  VBPopover,
  BFormSelectOption,
  
} from "bootstrap-vue";

//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    quillEditor,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormValidFeedback,
    BOverlay,
    Cleave,
    BAlert,
    VueSlider,
    BFormSpinbutton,
    BFormCheckboxGroup,
    AppCollapse,
    AppCollapseItem,
    TheFormSpinbuttonWithInput,
    BFormSelectOption
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [heightTransition],
  computed:{
  upperCaseNomGerant: {
      get() {
        return this.cabinet.nom_gerant;
      },
      set(value) {
        this.cabinet.nom_gerant = value.toUpperCase();
      }
    },
     formattedPrenomGerant: {
      get() {
        return this.cabinet.prenom_gerant;
      },
      set(value) {
        // Capitaliser la première lettre et mettre le reste en minuscules
        this.cabinet.prenom_gerant = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      }
    }
  },
  watch: {
    noSyncCodes(newVal) {
      this.produits.forEach(item => {
        const assureurNom = item.assureur.nom_com_assureur;
        const key = this.getKey(item);

        if (newVal.includes(assureurNom)) {
          // Si l'assureur est sélectionné dans noSyncCodes, vider et griser le champ
          this.$set(this.code, key, null);
        } else {
          // Sinon, garder la valeur actuelle et ne pas griser le champ
          this.$set(this.code, key, this.code[key] || '');
        }
      });
    },
    // 'activite.iard_professionnel': {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     if(val > 100){
    //       this.activite.iard_professionnel = 100;
    //       this.activite.iard_particulie = 0;
    //       this.activite.iard_entreprise = 0;
    //     }
    //     else
    //     {
    //       let total = 100 - val
    //       let div_total = total / 2
    //       this.activite.iard_entreprise = div_total
    //       this.activite.iard_particulie = div_total
    //     }
    //   }
    // },
    // 'activite.iard_entreprise': {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     if(val > 100){
    //       this.activite.iard_entreprise = 100;
    //       this.activite.iard_professionnel = 0;
    //       this.activite.iard_particulie = 0;
    //     }
    //     else
    //     {
    //       let total = 100 - val
    //       let div_total = total / 2
    //       this.activite.iard_particulie = div_total
    //       this.activite.iard_professionnel = div_total
    //     }
    //   }
    // },
  },
  data() {
    return {
      produits: [],
      snowOption: {
        theme: 'snow',
        placeholder: 'Coordonnées des mandataires sociaux (Nom, prénom, n° de téléphone et email)',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'strike']
        }
      },
      isDisabled:false,
      showLoading: true,
      userData: JSON.parse(localStorage.getItem('userData')),
      tarification_rapide:null,
      code :{
  
      },
      cabinet:{
        id: null,
        raison_sociale: null,
        denomination_commerciale: null,
        effectif: null,
        forme_juridique: null,
        n_siret: null,
        n_orias: null,
        capital_social: null,
        date_creation: null,
        adresse: null,
        code_postal: null,
        ville: null,
        tel: null,
        telfix: null,
        email:null,
        civilite_gerant:null,
        nom_gerant: null,
        prenom_gerant: null,
        fonction_gerant: null,
        experience_gerant: null,
        selected_profil: [],
        selected_profil_mandante: [],
        profile_activite: [],
        popup_demandecode_id:null
      },
      civilite: [
        { value: null, text: 'Choisissez' },
        { value: 'MR', text: 'M.' },
        { value: 'MME', text: 'Mme' }
      ],
      contact:
        [
          {
            nom: null,
            prenom: null,
            mail: null
          }
        ]
      ,
       noSyncCodes: [],
      activite:{
        error_mode_vente: true,
        error_repartition: true,
        repartition_iard: [],
        repartition_prevoyance: [],
        repartition_epargne: [],
        encaisse_fonds: null,
        en_creation: null,
        chiffre_affaire: null,
        mode_vente: [],
        complement_info_cas_vente_distence:[],
        iard_particulie: null,
        iard_professionnel: null,
        iard_entreprise: null,
        prevoyance_particulie: null,
        prevoyance_professionnel: null,
        prevoyance_entreprise: null,
        epargne_particulie: null,
        epargne_professionnel: null,
        epargne_entreprise: null,
        error_iard:false,
        error_prevoyance:false,
        error_epargne:false,
        autre_repartition_iard:null,
        autre_repartition_prevoyance:null,
        autre_repartition_epargne:null,
        fournisseur_lead:null,
        missions:null,
        relation_courtier_etablissement:null,
        nom_structure_juridique:null,
        nom_commercial:null,
        adress_complete:null,
        part_ca:null,
        nombre_managers:null,
        nombre_salaries:null,
        nombre_contrats_vendus:null,
        responsable_nom:null,
        responsable_prenom:null,
        responsable_mail:null,
        responsable_tele:null,
        repart_iard:null,
        repart_prevoyance:null,
        repart_epargne:null,
        error_repart_by_risque:false

      },
      devoir:{
        adresse:null,
        code_postal:null,
        ville:null,
        tel:null,
        email:null,
        site:null,
        adresse_mediation:null,
        code_postal_mediation:null,
        ville_mediation:null,
        tel_mediation:null,
        email_mediation:null,
        site_mediation:null,
        remuneration: [],
        autre_remuneration: null,
        lienEntreprises: [],
        lien_exc_entreprise: null,
        cabinet_un_entreprise: null,
        cabinet_des_entreprise: null,
      },
      signature: {
        checkbox: ['emission','ouverture']
      },
      optionsChiffreAffaire: [
          { text: '< 50 K€', value: '< 50 K€' },
          { text: 'De 50 à 100 K€', value: 'De 50 à 100 K€' },
          { text: 'De 100 à 150 K€', value: 'De 100 à 150 K€' },
          { text: 'De 150 à 200 K€', value: 'De 150 à 200 K€' },
          { text: '> 200 K€', value: '> 200 K€' }
      ],
      optionsEncaisseFonds: [
        {text: 'Oui', value: 'oui'},
        {text: 'Non', value: 'non'}
      ],
      en_creation: [
        {text: 'Oui', value: 'oui'},
        {text: 'Non', value: 'non'}
      ],
      options_profil_courtiers:[
        { text: 'Courtier de proximité', value: 'Courtier de proximité' },
        { text: 'Agent général et courtage à titre accessoire', value: 'Agent général et courtage à titre accessoire' },
        { text: 'Courtier à annexe', value: 'Courtier à annexe' },
        { text: 'Co-courtage', value: 'Co-courtage' },
      ],
      options_profil_mandante:[
        { text: 'Avec salariés', value: 'Avec salariés' },
        { text: 'Avec mandataires', value: 'Avec mandataires' }
      ],
    }
  
  },
 async  created() {
  await this.getAssureursPartenaires()
    this.initializeNoSyncCodes()
  },
  mounted() {
    this.tarification_rapide = this.$route.query.tarification_rapide ? this.$route.query.tarification_rapide : null
    this.getCourtierInformations(this.userData.courtier_user[0].courtier_id);
    this.blur_repatition_by_risque()
  },
  methods: {
    handleRepartitionChange(){
        const { repart_iard, repart_prevoyance, repart_epargne } = this.activite;
              // Vérifier si l'une des valeurs est null ou si la somme n'est pas 100%
              console.log(repart_iard)
              console.log(repart_prevoyance)
              console.log(repart_epargne)
              if (
                repart_iard === null ||
                repart_prevoyance === null ||
                repart_epargne === null ||
                (repart_iard + repart_prevoyance + repart_epargne) !== 100
              ) {
                this.activite.error_repart_by_risque = true;
              } else {
                this.activite.error_repart_by_risque = false;
              }
              console.log( this.activite.error_repart_by_risque)
            
    },
    getKey(item) {
      return item.assureur.nom_com_assureur.split(' ')[0].toLowerCase() + item.risque.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '');
    },
     initializeNoSyncCodes() {
      // Initialiser noSyncCodes en fonction des valeurs nulles dans code
      this.noSyncCodes = this.produits
        .filter(item => this.code[this.getKey(item)] === null)
        .map(item => item.assureur.nom_com_assureur);
    },
      isCodeDisabled(item) {
      // Désactiver le champ si l'assureur est dans noSyncCodes
      return this.noSyncCodes.includes(item.assureur.nom_com_assureur);
    },
    formatOldObjectInDB(inputObject){
      const formattedObject = {};
      for (const key in inputObject) {
        if (key=="harmonie"){
          formattedObject["harmoniesantetns"] = inputObject["harmonie"];
        } else if (key=="swisslife") {
          formattedObject["swisslifesanteseniors"] = inputObject["swisslife"];
        } else if (key=="fma") {
          formattedObject["fma2-roues"] = inputObject["fma"];
        } else if (key=="eca") {
          formattedObject["ecasanteseniors"] = inputObject["eca"];
        } else if (key=="spvie") {
          formattedObject["spviesanteseniors"] = inputObject["spvie"];
        } else {
          formattedObject[key] = inputObject[key];
        }
      }

      return formattedObject;
    }     ,
    capitalizeWords(str) {
          let words = str.toLowerCase().split(' ');
          for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
          }
          return words.join(' ');
        },

   async getAssureursPartenaires() {
            this.showLoading = true
            this.$http.get(`assureurs/getAssureursPartenaires`)
            .then((res) => {
              const produits = res.data
              const swisslife = produits.filter(p => p.assureur.nom_com_assureur === 'SWISSLIFE PREVOYANCE ET SANTE');
              const autresProduits = produits.filter(p => p.assureur.nom_com_assureur !== 'SWISSLIFE PREVOYANCE ET SANTE');
            // Fusionner les tableaux avec Swisslife à la fin
             this.produits = [...autresProduits, ...swisslife];
             this.showLoading = false
            })
            .catch((e) => {
                console.log(e);
                this.showLoading = false
            })
    },
    changeModeVente(){
      if(!this.activite.mode_vente.includes('agence') && !this.activite.mode_vente.includes('domicile') && !this.activite.mode_vente.includes('distance_tel') && !this.activite.mode_vente.includes('distance_internet'))
      {
        this.activite.error_mode_vente = true
      }
      else
      {
        this.activite.error_mode_vente = false
      }
    },
    repartitionCheck(){
      if(this.activite.repartition_iard.length == 0 && this.activite.repartition_prevoyance.length == 0 && this.activite.repartition_epargne.length == 0){
        this.activite.error_repartition = true
      }
      else
      {
        this.activite.error_repartition = false
      }
      
    },
    // repartitionByRisqueCheck(){
    //  if(this.activite.repart_iard == null && this.activite.repart_prevoyance == null && this.activite.repart_epargne == null){
    //     this.activite.error_repart_by_risque = true
    //   }
    //   else
    //   {
    //     this.activite.error_repart_by_risque = false
    //   }
      
    // },
    blur_iard(){
      let particulie = this.activite.iard_particulie ? this.activite.iard_particulie : 0;
      let professionnel = this.activite.iard_professionnel ? this.activite.iard_professionnel : 0;
      let entreprise = this.activite.iard_entreprise ? this.activite.iard_entreprise : 0;
      if((this.activite.iard_particulie == null || this.activite.iard_particulie == "") && (this.activite.iard_professionnel == null || this.activite.iard_professionnel == "") && (this.activite.iard_entreprise == null || this.activite.iard_entreprise == "")){
        if(this.activite.repartition_iard.length > 0){
          this.activite.error_iard = true;
        }
        else
        {
          this.activite.error_iard = false;
        }
      }
      else
      {
        if(parseFloat(particulie) + parseFloat(professionnel) + parseFloat(entreprise) == 100){
          this.activite.error_iard = false;
        }
        else
        {
          this.activite.error_iard = true;
        }
      }
      this.repartitionCheck()
    },
    blur_repatition_by_risque(){
     let iard = this.activite.repart_iard ? parseFloat(this.activite.repart_iard) : 0;
        let prevoyance = this.activite.repart_prevoyance ? parseFloat(this.activite.repart_prevoyance) : 0;
        let epargne = this.activite.repart_epargne ? parseFloat(this.activite.repart_epargne) : 0;

        // Vérification si tous les champs sont vides ou si la somme n'est pas égale à 100
        if ((iard + prevoyance + epargne === 0) || (iard + prevoyance + epargne !== 100)) {
          console.log("Erreur : Les champs sont tous vides ou la somme n'est pas égale à 100.");
          this.activite.error_repart_by_risque = true;
        } else {
          console.log("Les champs sont correctement remplis et la somme est égale à 100.");
          this.activite.error_repart_by_risque = false;
        }

        console.log(this.activite.error_repart_by_risque);
    },
    blur_prevoyance(){
      let particulie = this.activite.prevoyance_particulie ? this.activite.prevoyance_particulie : 0;
      let professionnel = this.activite.prevoyance_professionnel ? this.activite.prevoyance_professionnel : 0;
      let entreprise = this.activite.prevoyance_entreprise ? this.activite.prevoyance_entreprise : 0;
      if((this.activite.prevoyance_particulie == null || this.activite.prevoyance_particulie == "") && (this.activite.prevoyance_professionnel == null || this.activite.prevoyance_professionnel == "") && (this.activite.prevoyance_entreprise == null || this.activite.prevoyance_entreprise == "")){
        if(this.activite.repartition_prevoyance.length > 0){
          this.activite.error_prevoyance = true;
        }
        else
        {
          this.activite.error_prevoyance = false;
        }
      }
      else
      {
        if(parseFloat(particulie) + parseFloat(professionnel) + parseFloat(entreprise) == 100){
        this.activite.error_prevoyance = false;
        }
        else
        {
          this.activite.error_prevoyance = true;
        }
      }
      this.repartitionCheck()
    },
    blur_epragne(){
      let particulie = this.activite.epargne_particulie ? this.activite.epargne_particulie : 0;
      let professionnel = this.activite.epargne_professionnel ? this.activite.epargne_professionnel : 0;
      let entreprise = this.activite.epargne_entreprise ? this.activite.epargne_entreprise : 0;
      if((this.activite.epargne_particulie == null || this.activite.epargne_particulie == "") && (this.activite.epargne_professionnel == null || this.activite.epargne_professionnel == "") && (this.activite.epargne_entreprise == null || this.activite.epargne_entreprise == "")){
        if(this.activite.repartition_epargne.length > 0){
          this.activite.error_epargne = true;
        }
        else
        {
          this.activite.error_epargne = false;
        }
      }
      else
      {
        if(parseFloat(particulie) + parseFloat(professionnel) + parseFloat(entreprise) == 100){
        this.activite.error_epargne = false;
        }
        else
        {
          this.activite.error_epargne = true;
          console.log('erreur')
        }
      }
      this.repartitionCheck()
    },
    onSubmit(){
  
      if(!this.activite.error_iard && !this.activite.error_prevoyance && !this.activite.error_epargne && !this.activite.error_repart_by_risque){
        this.$refs.refFormObserver.validate()
              .then(success => {
                  this.isDisabled = true
                  this.$http.post(`courtier/generateDemandeCode`, {
                  code: this.code,
                  cabinet: this.cabinet,
                  contact: this.contact,
                  devoir: this.devoir,
                  activite: this.activite,
                  signature: this.signature,
                  tarification_rapide: this.tarification_rapide,
                  courtierUserCurrent: {userId: this.userData.id, courtierId: this.userData.courtier_user[0].courtier_id }
                  })
                  .then((r) => {
                    if(r.data.data.success){
                      this.messageToast(r.data.message, 'Succès', 'success', 'InfoIcon')
                      let urlSignature = r.data.data.url
                      window.open(urlSignature,"_blank");
                    }
                    else
                    {
                      this.messageToast('vous avez déja signé le document Fiche de demande de code', 'Erreur', 'warning','AlertTriangleIcon')
                    }
                    this.isDisabled = false
                  }).catch(err => {
                    console.log(err)
                    this.isDisabled = false
                  }) 
                
                  
              })
      }
      else
      {

      }
    },
    addContact(){
      this.contact.push({
            nom: null,
            prenom: null,
            mail: null
          })
    },
    DeleteContact(index){
      if(this.contact.length > 1){
        this.contact.splice(index,1)
      }
    },
    getCourtierInformations(courtierId){
      this.$http
            .get(`courtier/getDataDemandeCodeByCourtier/${courtierId}/${this.$route.params.popup_demandecode_id ? this.$route.params.popup_demandecode_id : null}`)
            .then(res => {
              if(Object.keys(res.data.data).length > 0){
                let courtier = res.data.data
                this.cabinet.id = courtierId
                //this.cabinet = courtier.cabinet
                Object.assign(this.cabinet, courtier.cabinet);
                // this.contact = courtier.contact
                Object.assign(this.contact, courtier.contact);
                // this.devoir = courtier.devoir
                Object.assign(this.devoir, courtier.devoir);
                // this.activite = courtier.activite
                Object.assign(this.activite, courtier.activite);
                Object.assign(this.code, this.formatOldObjectInDB(courtier.code));
    
              }
              else
              {
                  this.$http
                  .get('courtier/fetchDataCourtier/'+courtierId)
                  .then(res => {
                  if(res.data){
                    let courtier = res.data.data
                    this.cabinet.id = courtierId
                    this.cabinet.raison_sociale = courtier.raison_sociale
                    this.cabinet.denomination_commerciale = courtier.denomination_commercial
                    this.cabinet.effectif = courtier.effectif
                    this.cabinet.forme_juridique = courtier.forme_juridique
                    this.cabinet.n_siret = courtier.siret
                    this.cabinet.capital_social = courtier.capital_social
                    if (courtier.date_creation != null) {
                      this.cabinet.date_creation = moment(courtier.date_creation).format('DD/MM/YYYY')
                    }
                    if (courtier.moyen_contact != null) {
                      if (courtier.moyen_contact.ville != null) [
                        this.cabinet.ville = courtier.moyen_contact.ville.commune
                      ]
                      this.cabinet.adresse = courtier.moyen_contact.libelle
                      this.cabinet.code_postal = courtier.moyen_contact.code_postal
                      this.cabinet.tel = courtier.moyen_contact.tel
                      this.cabinet.email = courtier.moyen_contact.email
                    }
                    if (courtier.courtier != null) {
                      this.cabinet.n_orias = courtier.courtier.numero_orias
                      this.cabinet.nom_gerant = courtier.courtier.nom_dirigeant
                      this.cabinet.prenom_gerant = courtier.courtier.prenom_dirigeant
                      if (courtier.courtier.statistiquepopupdemandecode != null) {
                        this.cabinet.popup_demandecode_id = courtier.courtier.statistiquepopupdemandecode.id
                      }
                    }
                  }
                  this.showLoading = false
              })
              .catch(err => {
                this.showLoading = false
                console.log(err)
              })
              }
              this.showLoading = false
            })
            .catch(err => {
              this.showLoading = false
              console.log(err)
            })
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';
@import '@core/scss/vue/libs/quill.scss';

.block-cylindre .custom-checkbox .custom-control-label::before,
.block-cylindre .custom-checkbox .custom-control-label::after {
    top: 0.7rem;
}

.label-cylindre {
  margin-top: 0.7rem;
  min-width: 125px;
}

.title-custom-wizard{
    color: #4d25bc ;
    margin-bottom: 0px;
}
.text_info
{
  display: block;
  margin-top: 5px;
  color: #4d25bc ;
  font-style: italic;
  font-size:0.9rem;
  font-weight: 600;
}
.signature-checkbox .custom-control-input[disabled] ~ .custom-control-label,.signature-checkbox .custom-control-input:disabled ~ .custom-control-label
{
  color:#5e5873;
}
.profil_checkbox .custom-checkbox.custom-control
{
  padding-bottom: 10px;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.min-w-150 {
  min-width: 150px
}

#collapse-besoin .card:has(div.d-block.invalid-feedback, input.is-invalid) span h4 {
    color: #ea5455;
}

.w-80px {
  width: 80px!important;
}

.w-100px {
  width: 100px!important;
}
</style>

<style lang="scss" scoped>
label{
    font-weight: 900;
}

.w-125 {
  width: 125px;
}
.w-190 {
  width: 190px!important;
}
.h-40p {
  height: 40px;
}
</style>
